import { makeStyles } from "@material-ui/core/styles";
import { mobileViewBreakpoint } from "../../helper";
export const customStyle = makeStyles((theme) => ({
  socialinks: {
    display: "flex",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexWrap: "wrap",
      marginTop: "-13px",
    },
    "& a": {
      marginRight: "40px",
      "&:last-child": {
        marginRight: "0px",
      },
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        marginLeft: "32px",
        marginRight: "32px",
        marginTop: "80px",

        "&:last-child": {
          marginRight: "32px",
        },
      },
    },
  },
}));
