import { makeStyles } from "@material-ui/core/styles";
import { mobileViewBreakpoint } from "../../helper";
export const customStyle = makeStyles((theme) => ({
  detailsWrapper: {
    paddingBottom: "18px",
    color: "#ffffff",
    "&:last-child": {
      paddingBottom: "0px",
    },
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      paddingBottom: "32px",
      "&:last-child": {
        paddingBottom: "0px",
      },
    },
    "& p": {
      color: "#ffffff",
    },
    "& a": {
      color: "#ffffff",
      "&:hover": {
        color: "#ffffff",
        textDecoration: "none",
      },
    },
  },
}));
