export const subMenuAccordionData = [
  {
    title: "Event Transport",
    description:
      "We specialize in event transport and management. No project is too large or small.",
    link: "Read More",
    url: "/event-transportation-service/"
  },
  {
    title: "Transport Services",
    description:
      "MOTUS | ONE plans, implements and manages transport systems of any size, type and complexity.",
    link: "Read More",
    url: "/transportation-management-service/"
  },
  {
    title: "Logistics",
    description:
      "Our teams simplify the most complex movements of people, plant materials.",
    link: "Read More",
    url: "/event-logistics-service/"
  }
];
