import React from "react";
import { customStyle } from "./Footer.styles";
import Title from "./Title/Title";
import Links from "./Links/Links";
import Container from "@material-ui/core/Container";
import Socialinks from "./SocialLinks/SocialLinks";
import LogoImage from "../../assets/images/MOTUS_WHITE_LOGO.png";
import { Link } from "gatsby";
const Footer = () => {
  const classes = customStyle();

  return (
    <div className={classes.footerWrapper}>
      <Container className={classes.containerWrapper} maxWidth={false}>
        <div className={classes.gridWrapper}>
          <div className={classes.iconGrid}>
            <a href="https://motusone.com/">
              <img
                className={classes.iconLogoWrapper}
                src={LogoImage}
                width="160"
                height="40"
                alt="motus one the event transportation company logo white"
              />
            </a>
            <Socialinks />
          </div>

          <div className={classes.grid}>
            <Title text="What we do" />
            <Links
              href="/event-transportation-service/"
              text="Event Transportation"
            />
            <Links
              href="/transportation-management-service/"
              text="Corporate Transportation"
            />
            <Links
              href="/event-logistics-service/"
              text="Transportation consulting"
            />
            <Links href="/expo-transport-services/" text="Expo Transport" />
          </div>
          <div className={classes.grid}>
            <Title text="About us" />
            <Links href="/about-us/" text="About Us" />
            <Links href="/contact-us/" text="Contact Us" />
            <Links href="/blog/" text="Blog" />
            <Links href="/sitemap" text="Sitemap" />
          </div>
          <div className={classes.grid}>
            <Title text="Case Studies" />
            <Links href="/case-studies/dakar-rally/" text=" Dakar Rally" />
            <Links
              href="/case-studies/ad-diriyah-seasons-riyadh/"
              text="Ad-Diriyah Seasons"
            />
            <Links
              href="/case-studies/abu-dhabi-formula-one/"
              text="Abu Dhabi Formula One®"
            />
            <Links
              href="/case-studies/nba-india-games/"
              text="NBA India Games"
            />
            <Links
              href="/case-studies/strategic-fleet-optimization-utilization/"
              text="Strategic Fleet Optimization"
            />
            <Links
              href="/case-studies/transportation-solutions-rethought/"
              text="Transportation Solutions"
            />
          </div>
        </div>
      </Container>
    </div>
  );
};
export default Footer;
