import { makeStyles } from "@material-ui/core/styles";

const allClassStyles = {
  h1: {
    fontSize: "60px",
    lineHeight: "70px",
    letterSpacing: "-0.7px"
  },
  h2: {
    fontSize: "40px",
    lineHeight: "48px",
    letterSpacing: "-0.4px"
  },
  h3: {
    fontSize: "30px",
    lineHeight: "37px",
    letterSpacing: "-0.35px"
  },
  h4: { fontSize: "24px", lineHeight: "34px" },
  h5: { fontSize: "20px", lineHeight: "30px" },
  bodySmall: { fontSize: "14px", lineHeight: "134.3%", letterSpacing: "0.3px" },
  bodyLarge: { fontSize: "16px", lineHeight: "134.3%", letterSpacing: "0.3px" },
  cta: { fontSize: "14px", lineHeight: "16px", letterSpacing: "0.3px" },
  label: {
    fontSize: "10px",
    lineHeight: "16px",
    letterSpacing: "0.3px",
    textTransform: "uppercase"
  }
};

export const customVariantList = [
  "h1Bold",
  "h1Regular",
  "h2Bold",
  "h2Regular",
  "h3Bold",
  "h3Regular",
  "h4Bold",
  "h4Regular",
  "h5Bold",
  "h5Regular",
  "bodySmallBold",
  "bodySmallRegular",
  "bodyLargeBold",
  "bodyLargeRegular",
  "ctaBold",
  "ctaRegular",
  "labelBold",
  "labelRegular"
];

export const getClassStyles = customVariant => {
  if (customVariant.includes("h1")) return allClassStyles.h1;
  if (customVariant.includes("h2")) return allClassStyles.h2;
  if (customVariant.includes("h3")) return allClassStyles.h3;
  if (customVariant.includes("h4")) return allClassStyles.h4;
  if (customVariant.includes("h5")) return allClassStyles.h5;
  if (customVariant.includes("bodySmall")) return allClassStyles.bodySmall;
  if (customVariant.includes("bodyLarge")) return allClassStyles.bodyLarge;
  if (customVariant.includes("cta")) return allClassStyles.cta;
  if (customVariant.includes("label")) return allClassStyles.label;
};

export const getComponent = customVariant => {
  if (customVariant.includes("h1")) return "h1";
  if (customVariant.includes("h2")) return "h2";
  if (customVariant.includes("h3")) return "h3";
  if (customVariant.includes("h4")) return "h4";
  if (customVariant.includes("h5")) return "h5";
  if (
    customVariant.includes("body") ||
    customVariant.includes("cta") ||
    customVariant.includes("label")
  )
    return "p";
};

const getFontWeightValue = props => {
  if (props.customVariant === "labelBold") return 900;
  else if (props.customVariant.includes("Bold")) return "bold";
  else return "normal";
};

// Using theme callback just in case we need to make styles responsive
export const useStyles = makeStyles(() => ({
  root: props => ({
    fontWeight: getFontWeightValue(props),
    color: props.color || "initial",
    ...props.classStyles
  })
}));
