import React, { useState } from "react";
import { Link } from "gatsby";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Typography from "../../../Typography/Typography";
import { useStyles } from "./MenuAccordion.styles";
import Button from "../../../Button/Button";
import { Divider, List } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SubMenu from "../SubMenu/SubMenu";

const MenuAccordion = () => {
  const classes = useStyles();
  const anchor = "right";
  const [parentMenu, setParentMenu] = useState("");
  const [state, setState] = useState({
    right: false
  });

  const toggleDrawer = (anchor, open) => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setParentMenu(parentMenu);
    setState({ ...state, [anchor]: open });
  };

  return (
    <div className={classes.root}>
      <Button className={classes.loginButton}>Login</Button>
      <List className="listgroup">
        <ListItem onClick={toggleDrawer(anchor, true)}>
          <ListItemText className={classes.primaryMenu} primary="What we do" />
          <ListItemIcon className={classes.primaryMenuIcon}>
            <ChevronRightIcon />
          </ListItemIcon>
        </ListItem>
        <Divider />
        <ListItem className={classes.linkMobile}>
          <a href="/about-us/">
            <ListItemText className={classes.primaryMenu} primary="About us" />
            <ListItemIcon className={classes.primaryMenuIcon}>
              <ChevronRightIcon />
            </ListItemIcon>
          </a>
        </ListItem>
        <Divider />
        <ListItem className={classes.linkMobile}>
          <a href="/case-studies/">
            <ListItemText
              className={classes.primaryMenu}
              primary="Case Studies"
            />
            <ListItemIcon className={classes.primaryMenuIcon}>
              <ChevronRightIcon />
            </ListItemIcon>
          </a>
        </ListItem>
        <Divider />
        <ListItem className={classes.linkMobile}>
          <a href="/contact-us/">
            <ListItemText
              className={classes.primaryMenu}
              primary="Contact Us"
            />
            <ListItemIcon className={classes.primaryMenuIcon}>
              <ChevronRightIcon />
            </ListItemIcon>
          </a>
        </ListItem>
        <Divider />
        <ListItem className={classes.linkMobile}>
          <a href="/blog">
            <ListItemText className={classes.primaryMenu} primary="Blog" />
            <ListItemIcon className={classes.primaryMenuIcon}>
              <ChevronRightIcon />
            </ListItemIcon>
          </a>
        </ListItem>
        <Divider />
      </List>

      <SubMenu
        anchor={anchor}
        open={state[anchor]}
        onClose={toggleDrawer(anchor, false)}
      />
    </div>
  );
};
export default MenuAccordion;
