import React, { useState } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "../../../Typography/Typography";
import { Divider, List } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useStyles } from "./../MenuAccordion/MenuAccordion.styles";
import { subMenuAccordionData } from "../mobileMenu.helper";
import { Link } from "gatsby";

const SubMenu = ({ anchor, open, onClose, data, parentMenuTitle }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState();
  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const list = anchor => {
    return (
      <div
        className={clsx(classes.list, {
          [classes.fullList]: anchor === "top" || anchor === "bottom"
        })}
        role="presentation"
      >
        <div className={classes.menuDetails123}>
          <List>
            <ListItem ListItem button onClick={onClose}>
              <ListItemIcon className={classes.subMenuBackIcon}>
                <ArrowBackIosIcon />
              </ListItemIcon>
              <ListItemText
                className={classes.subMenuTitle}
                primary="Our Transport &amp; Logistics Services"
              />
            </ListItem>
            <div className={classes.subMenuDescription}>
              <Typography customVariant={"bodyLargeRegular"}>
                You can rely on MOTUS | ONE to deliver world-class
                event-transport management and consultancy. We offer a
                comprehensive suite of services, all tailored to your exact
                needs.
              </Typography>
              <div
                className={clsx(
                  classes.linkWrapper,
                  classes.linkWrapperAccordion
                )}
              >
                <a href="/services-overview/">
                  <ChevronRightIcon /> <span>Read More</span>
                </a>
              </div>
            </div>
          </List>
          <Divider />
          {subMenuAccordionData.map((accordionItem, index) => {
            return (
              <Accordion
                className={classes.mainAccordion}
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
              >
                <AccordionSummary
                  className={classes.accordionTitle}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography customVariant={"bodyLargeBold"}>
                    {accordionItem.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionContent}>
                  <Typography customVariant={"bodyLargeRegular"}>
                    {accordionItem.description}
                  </Typography>
                  <div className={classes.linkWrapper}>
                    <a href={accordionItem.url}>
                      <ChevronRightIcon /> <span>Read More</span>
                    </a>
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </div>
    );
  };
  return (
    <div>
      <Drawer anchor={anchor} open={open} onClose={onClose}>
        {list(anchor)}
      </Drawer>
    </div>
  );
};

export default SubMenu;
