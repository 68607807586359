import React from "react";
import FaceBook from "../../../assets/images/footer/facebook.svg";
import { customStyle } from "./SocailLinks.styles";
import Mail from "../../../assets/images/footer/mail.svg";
import Phone from "../../../assets/images/footer/phone.svg";
import Twitter from "../../../assets/images/footer/twitter.svg";
import LinkedIn from "../../../assets/images/footer/linkedIn.svg";
import Instagram from "../../../assets/images/footer/instagram.svg";

const Socialinks = () => {
  const classes = customStyle();
  return (
    <div className={classes.socialinks}>
      <a
        href="https://www.instagram.com/motusone1/"
        target="_blank"
        rel="noopener"
      >
        <img src={Instagram} alt="instagram" width="18" height="18" />
      </a>
      <a
        href="https://www.linkedin.com/company/motus-one/"
        target="_blank"
        rel="noopener"
      >
        <img src={LinkedIn} alt="linkedin" width="18" height="17" />
      </a>
      <a
        href="https://www.facebook.com/motusone"
        target="_blank"
        rel="noopener"
      >
        <img src={FaceBook} alt="facebook" width="10" height="18" />
      </a>
      <a href="https://twitter.com/motus1ne" target="_blank" rel="noopener">
        <img src={Twitter} alt="Twitter" width="20" height="17" />
      </a>
      <a href="tel: +971 (0)4 874 6677" rel="noopener">
        <img src={Phone} alt="phone number" width="19" height="19" />
      </a>

     
    </div>
  );
};
export default Socialinks;
