import React from "react";
import { useState } from "react";
import Container from "@material-ui/core/Container";
import Typography from "../../Typography/Typography";
import Arrows from "../../../assets/images/menu/arrow.svg";
import { useStyles } from "./MenuDetails.styles";
import { Link } from "gatsby";

const MenuDetailList = () => {
  const classes = useStyles();
  return (
    <div>
      <hr></hr>
      <Container maxWidth="lg" className={classes.containerWrapper}>
        <div>
          <div className={classes.subTitleWrapper}>
            <Typography customVariant="h4Bold">Event Transport</Typography>
          </div>
          <div className={classes.menuDetailList}>
            <Typography customVariant="h5Regular">
              We specialize in event transport and management. No project is too
              large or small.
            </Typography>
          </div>
          <div className={classes.linkButtonWrapper}>
            <img src={Arrows} />
            <a href="/event-transportation-service/">Read More</a>
          </div>
        </div>
        <div>
          <div className={classes.subTitleWrapper}>
            <Typography customVariant="h4Bold">Transport Services</Typography>
          </div>
          <div className={classes.menuDetailList}>
            <Typography customVariant="h5Regular">
              MOTUS | ONE plans, implements and manages transport systems of any
              size, type and complexity.
            </Typography>
          </div>
          <div className={classes.linkButtonWrapper}>
            <img src={Arrows} />

            <a href="/transportation-management-service/">Read More</a>
          </div>
        </div>
        <div>
          <div className={classes.subTitleWrapper}>
            <Typography customVariant="h4Bold">Logistics</Typography>
          </div>
          <div className={classes.lastMenuDetailList}>
            <Typography customVariant="h5Regular">
              Our teams simplify the most complex movements of people, plant
              materials.
            </Typography>
          </div>
          <div className={classes.linkButtonWrapper}>
            <img src={Arrows} />
            <a href="/event-logistics-service/">Read More</a>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default MenuDetailList;
