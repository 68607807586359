import Typography from "../../Typography/Typography";

import React from "react";
import { customStyle } from "./Title.styles";
const Title = ({ text }) => {
  const classes = customStyle();
  return (
    <div className={classes.titleWrapper}>
      <Typography
        customVariant={"h4Bold"}
        component="div"
        className={classes.footerText}
      >
        {text}
      </Typography>
    </div>
  );
};
export default Title;
