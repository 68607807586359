import Typography from "../../Typography/Typography";

import React from "react";
import { Link } from "gatsby";
import { customStyle } from "./Links.styles";
const Links = ({ text, href }) => {
  const classes = customStyle();
  return (
    <div className={classes.detailsWrapper}>
      <Typography customVariant={"bodyLargeRegular"}>
        <a href={href}>{text}</a>
      </Typography>
    </div>
  );
};
export default Links;
