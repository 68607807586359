import React from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "../../Typography/Typography";
import Container from "@material-ui/core/Container";
import { customStyle } from "./CopyRights.styles";
//import Privacy from "../Privacy/Privacy"; temp hide privacy
import { mobileViewBreakpoint } from "../../helper";

const CopyRights = () => {
  const classes = customStyle();
  const theme = useTheme();

  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );

  return (
    <div className={classes.copyRightsMainWrapper}>
      <Container maxWidth={false} className={classes.copyRightContainer}>
        <div className={classes.copyRightWrapper}>
          {!isMobileView && (
            <div className={classes.copyWrapper}>
              <Typography customVariant={"bodyLargeRegular"}>
                © 2024 MOTUS | ONE Parking Management Services LLC. All rights
                reserved
              </Typography>
            </div>
          )}

          {/*<Privacy></Privacy>*/}

          {isMobileView && (
            <div className={classes.copyWrapper}>
              <Typography customVariant={"bodyLargeRegular"}>
                © 2024 MOTUS | ONE Parking Management Services LLC. All rights
                reserved
              </Typography>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};
export default CopyRights;
