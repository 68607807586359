import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
  menuDetailsWrapper: {
    marginTop: "40px",
    marginBottom: "44px",
    "& hr": {
      border: "1px solid rgba(0, 0, 0, 0.1)",
      margin: 0,
    },
  },
  menuTopContent: {
    paddingLeft: "32px",
    paddingRight: "32px",
    paddingBottom: "40px",
  },
  typographyh2: {
    marginBottom: "32px",
    "& h2": {
      color: "#122044",
      fontWeight: "bold",
    },
  },
  typography: {
    marginBottom: 16,
    maxWidth: "840px",

    "& h5": {
      color: "#122044",
    },
  },
  linkButtonWrapper: {
    marginBottom: "20px",
    display: "flex",
    alignItems: "center",
    marginTop: "32px",
    "& a": {
      color: "#CC4A01!important",
      marginLeft: "12px",
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "0.3px",
      "&:hover": {
        color: "#CC4A01",
        textDecoration: "none",
      },
    },
  },
  linkButtonWrapperTop: {
    marginBottom: "0px",
    display: "flex",
    alignItems: "center",
    marginTop: "16px",
    "& a": {
      color: "#CC4A01!important",
      marginLeft: "12px",
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "0.3px",
      "&:hover": {
        color: "#CC4A01",
        textDecoration: "none",
      },
    },
  },
  containerWrapper: {
    justifyContent: "space-between",
    flexDirection: "row",
    display: "flex",
    paddingLeft: "32px",
    paddingRight: "32px",
    paddingTop: "40px",
  },
  subTitleWrapper: {
    marginBottom: "8px",
    "& h4": {
      color: "#122044",
    },
  },
  menuDetailList: {
    marginRight: "32px",
    marginBottom: "12px",
    "& h5": {
      color: "#122044",
    },
  },
  lastMenuDetailList: {
    marginBottom: "12px",
    "& h5": {
      color: "#122044",
    },
  },
}));
