import { makeStyles } from "@material-ui/core/styles";
import { mobileViewBreakpoint } from "../helper";

export const customStyle = makeStyles((theme) => ({
  footerWrapper: {
    background: "#122044",

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      height: "auto",
    },
  },
  containerWrapper: {
    paddingTop: "240px",
    paddingBottom: "120px",
    paddingLeft: 32,
    paddingRight: 32,
    maxWidth: 1234,
    margin: "0 auto",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      paddingTop: "64px",
      paddingBottom: "80px",
      paddingLeft: "16px",
      paddingRight: "16px",
    },
  },
  gridWrapper: {
    justifyContent: "space-between",

    flexDirection: "row",
    display: "flex",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      flexDirection: "column",
    },
  },
  iconLogoWrapper: {
    width: "161px",
    height: "42px",
    marginBottom: "50px",
    color: "ehite",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      marginBottom: "0px",
    },
  },

  iconGrid: {
    marginRight: "60px",
    marginBottom: "30px",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      marginRight: "0px",
      justifyContent: "center",
      textAlign: "center",
    },
  },
  grid: {
    marginRight: "60px",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      marginRight: "0px",
      justifyContent: "center",
      textAlign: "center",
      marginBottom: "0px",
      "&:second-child": {
        marginTop: "-2px",
      },
    },
  },
}));
