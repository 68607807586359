import { withStyles } from "@material-ui/core/styles";
import MuiButton from "@material-ui/core/Button";

import {
  labelBaseStyles,
  containedFocusVisible,
  baseIconRootStyles,
  disabledBaseStyles,
  labelContainedStyles
} from "./style";

// The `withStyles()` higher-order component is injecting a `classes`
// prop that is used by the `Button` component.
export const ContainedButton = withStyles({
  root: {
    background: "#4001C5",
    width: 167,
    height: 40,
    borderRadius: "3px",
    padding: "11px 26px",
    boxShadow: "none",
    fontWeight: "bold",
    "& .MuiButton-startIcon svg path": {
      fill: "#FFF"
    },
    "& .MuiButton-startIcon svg": {
      ...baseIconRootStyles
    },
    "&:hover": { background: "rgba(64, 1, 197, 0.6)", boxShadow: "none" }
  },
  label: {
    ...labelBaseStyles,
    ...labelContainedStyles,
    color: "#FFF",
    letterSpacing: "-0.2px",
    fontWeight: "bold"
  },
  focusVisible: {
    ...containedFocusVisible
  },
  disabled: {
    ...disabledBaseStyles,
    background: "rgba(0, 0, 0, 0.2)"
  }
})(MuiButton);

/*
export const ContainedButtonSecondary = withStyles({
  root: {
    background: "#FFF",
    borderRadius: "3px",
    border: "1px solid #4001C5",
    padding: "11px 26px",
    boxShadow: "none",
    "& .MuiButton-startIcon svg path": {
      fill: "#4001C5"
    },
    "& .MuiButton-startIcon svg": {
      ...baseIconRootStyles
    },
    "&:hover": {
      background: "rgba(64, 1, 197, 0.6)",
      border: "1px solid rgb(64, 1, 197, 0.1)",
      boxShadow: "none"
    },
    "&:hover > .MuiButton-label": { color: "#FFF", boxShadow: "none" },
    "&:hover .MuiButton-startIcon svg path": { fill: "#FFF" }
  },
  label: {
    ...labelBaseStyles,
    ...labelContainedStyles,
    color: "#4001C5"
  },
  focusVisible: {
    ...containedFocusVisible,
    "& $label": {
      color: "#FFF"
    },
    "& .MuiButton-startIcon svg path": {
      fill: "#FFF"
    }
  },
  disabled: {
    ...disabledBaseStyles,
    background: "rgba(0, 0, 0, 0.1)",
    border: "1px solid rgba(0, 0, 0, 0.02)"
  }
})(MuiButton);
*/
