import React from "react";
import { Typography as MuiTypography } from "@material-ui/core";
import PropTypes from "prop-types";

import {
  getClassStyles,
  useStyles,
  getComponent,
  customVariantList
} from "./helpers";

/**
 * Typography is a wrapper for Mui's base Typography component. It supports all typography styles contained in the Figma style guide. All props other than the ones documented get passed through to the Mui component if you need to use Mui's API to customize this component further. **IGNORE THE COLOR PROP, USE THE STYLE PROP TO CHANGE THE COLOR IF NECESSARY.**
 */

const Typography = props => {
  const {
    customVariant,
    children,
    component,
    color,
    style,
    className,
    ...other
  } = props;

  const classStyles = getClassStyles(customVariant);
  const elementBasedOnVariant = getComponent(customVariant);
  const classes = useStyles({ customVariant, classStyles, color });

  return (
    <MuiTypography
      className={[className, classes.root].join(" ")}
      style={style}
      component={component || elementBasedOnVariant}
      {...other}
    >
      {children}
    </MuiTypography>
  );
};

Typography.propTypes = {
  customVariant: PropTypes.oneOf(customVariantList),
  component: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.object
};

export default Typography;
