import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  sectionWrapper: ({ trigger }) => ({
    transition: "all ease 0.3s",
    position: "fixed",
    top: 0,
    backgroundColor: "#ffffff",
    width: "100%",
    height: trigger ? 68 : 104,
    display: "flex",
    flexDirection: "row",
    zIndex: 1400
  }),
  list: {
    width: "100%"
  },
  drawerWrapper: {
    "& .MuiPaper-root": {
      width: "100%",
      background:
        "linear-gradient(96.34deg, #FCFCFC 8.92%, #FCFCFC 8.93%, #F8FAFF 100%)"
    }
  },
  logoWrapper: {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)"
  },
  closeLogoWrapper: {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    "& img": {
      width: "100px"
    }
  },
  listWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    paddingLeft: "16px",
    paddingRight: "4px",
    padding: 0,
    border: "1px solid rgba(0, 0, 0, 0.05)"
  },
  dividerWrapper: {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    height: "100%"
  }
}));
