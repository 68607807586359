import { makeStyles } from "@material-ui/core/styles";
import { mobileViewBreakpoint } from "../../helper";
export const customStyle = makeStyles(theme => ({
  copyRightsMainWrapper: {
    background: "#122044",
    borderTop: "1px solid rgba(255, 255, 255, 0.1)"
  },
  copyRightWrapper: {
    paddingBottom: "240px",
    paddingTop: "40px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    maxWidth: "100%",
    margin: "0 auto",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      flexDirection: "column",
      textAlign: "center",
      paddingBottom: "72px",
    },
  },
  copyRightContainer:{
    maxWidth: 1234,
    paddingLeft: 32,
    paddingRight: 32,
  },
  copyWrapper: {
    "& p": {
      color: "#ffffff"
    },
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      paddingLeft: "28px",
      paddingRight: "28px",
      textAlign: "center",
      paddingTop: "20px",
      "& p": {
        color: "rgba(255, 255, 255, 0.5)",
      },
    },
  },
}));
