import { makeStyles } from "@material-ui/core/styles";
import {
  mobileViewBreakpoint,
  extraLargeDesktopViewBreakpoint,
} from "../helper";
export const useStyles = makeStyles((theme) => ({
  root: ({ trigger }) => ({
    flexGrow: 1,
    margin: trigger ? undefined : "0 auto",
    width: !trigger ? 1234 : "100%",
    borderRadius: trigger ? 6 : 0,
    zIndex: 100,
    margin: "0 auto",
    padding: "0px 32px",
    //transition: ".5s"
    "& .MuiToolbar-gutters": {
      paddingLeft: "0px !important",
      paddingRight: "0px !important",
      "& a": {
        "&:hover": {
          textDecoration: "none",
        },
      },
    },
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      display: "none",
    },
  }),
  container: ({ trigger }) => ({
    position: !trigger ? "absolute" : "fixed",
    left: "50%",
    transition: "all 0.15s cubic-bezier(0.42, 0, 0, 0.81) 0s",
    transform: !trigger ? "translate(-50%, 64px)" : "translate(-50%, 0px)",
    width: !trigger ? 1234 : "100%",
    margin: "0 auto",
    zIndex: 999,
    backgroundColor: "#fff",
    border: "1px solid rgba(0, 0, 0, 0.05)",
    boxSizing: "border-box",
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
    borderRadius: "6px",
  }),
  menuContainer: {
    paddingLeft: "32px!important",
    paddingRight: "32px!important",
    maxWidth: "1234px",
  },
  appBar: ({ trigger }) => ({
    boxSizing: "border-box",
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
    borderRadius: !trigger ? "6px" : 0,
  }),
  title: {
    flexGrow: 1,
    paddingTop: 28,
    paddingBottom: 20,
    "& a": {
      display: "inline-block",
    },
  },
  typography1: {
    marginBottom: 32,
  },
  typography2: {
    marginBottom: 16,
  },
  typographyWrapper: {
    marginLeft: 36,
  },
  MenuOpenWrapper: {
    marginTop: 40,
    marginBottom: 64,
  },
  mobileViewlogin: {
    marginBottom: 40,
  },
  linkWrapper: {
    marginRight: 40,
    cursor: "pointer",
  },
  logoImage: {
    height: 40,
  },
  borderBottomWrapper: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
  },
}));
