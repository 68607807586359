import { makeStyles } from "@material-ui/core/styles";
import { mobileViewBreakpoint } from "../../helper";
export const customStyle = makeStyles(theme => ({
  titleWrapper: {
    paddingBottom: "18px",
    color: "#ffffff",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      paddingBottom: "32px",
      paddingTop: "64px"
    }
  },
  footerText: {
    color: "#ffffff"
  }
}));
