import React, { useState } from "react";
import { Divider, List } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { useStyles } from "./MobileMenu.styles";
import logo from "../../../assets/images/MOTUS_COLOR_LOGO.png";
import MenuHamburgerButton from "../../../assets/images/menu/menu-hamburger-button.svg";
import MenuAccordion from "../MobileMenu/MenuAccordion/MenuAccordion";
import Close from "../../../assets/images/menu/close.svg";
import clsx from "clsx";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Link from "gatsby-link";

const MobileMenu = ({ window }) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined
  });
  const classes = useStyles({ trigger });
  const anchor = "right";
  const [state, setState] = React.useState({
    right: false
  });

  const toggleDrawer = (anchor, open) => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const list = anchor => (
    <div className={clsx(classes.list)} role="presentation">
      <MenuAccordion />
    </div>
  );
  return (
    <>
      <section className={classes.sectionWrapper}>
        <div className={classes.listWrapper}>
          <span
            className={
              state.right ? classes.closeLogoWrapper : classes.logoWrapper
            }
          >
            <a href="https://motusone.com/">
              <img
                src={logo}
                alt="motus one the event transportation company logo"
                aria-label="MOTUS | ONE logo"
                width="96"
                height="24"
              />
            </a>
          </span>

          <Divider orientation="vertical" className={classes.dividerWrapper} />

          <>
            <React.Fragment>
              <IconButton
                onClick={toggleDrawer(anchor, !state.right)}
                aria-label="Close navigation"
              >
                <span>
                  <img
                    src={state.right ? Close : MenuHamburgerButton}
                    width="44"
                    height="49"
                    alt="hamburger menu"
                  />
                </span>
              </IconButton>

              <SwipeableDrawer
                className={classes.drawerWrapper}
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                onOpen={toggleDrawer(anchor, true)}
              >
                {list(anchor)}
              </SwipeableDrawer>
            </React.Fragment>
          </>
        </div>
      </section>
    </>
  );
};
export default MobileMenu;
