import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

const drawerCollapsedWidth = 0;
export const useStyles = makeStyles(({ transitions, theme }) =>
  createStyles({
    root: {
      background:
        "linear-gradient(96.34deg, #FCFCFC 8.92%, #FCFCFC 8.93%, #F8FAFF 100%)",
      marginBottom: "104px",
      marginTop: "104px",
      "& .MuiAccordion-root": {
        boxShadow: "none",
        margin: 0,

        "&::before": {
          background: "none",
        },
      },

      "& .MuiAccordionSummary-root": {
        background:
          "linear-gradient(96.34deg, #FCFCFC 8.92%, #FCFCFC 8.93%, #F8FAFF 100%)",
        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        height: "56px",
        paddingLeft: "16px",
        paddingRight: "22px",
      },
      "& .MuiAccordionSummary-root.Mui-expanded": {
        borderBottom: "0px solid rgba(0, 0, 0, 0.1)",
      },
      "& .Mui-expanded svg": {
        fill: "#4001C5",
      },

      "& .MuiAccordionDetails-root": {
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingBottom: "24px",
        display: "block",
        lineHeight: "134.3%",
        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        background:
          "linear-gradient(96.34deg, #FCFCFC 8.92%, #FCFCFC 8.93%, #F8FAFF 100%)",
      },
    },
    linkWrapper: {
      paddingTop: "24px",
      "& a": {
        color: "#CC4A01!important",
        margin: "8px 0px",
        fontWeight: "bold",
        fontSize: "14px",
        lineHeight: "16px",
        letterSpacing: "0.3px",
        display: "flex",
        alignItems: "center",
        "& > span": {
          paddingLeft: "14px",
        },
      },
    },
    loginButton: {
      marginTop: "48px",
      marginBottom: "44px",
      marginLeft: "16px",
      width: "164px",
    },
    menuDetails123: {
      margin: "110px auto",
    },
    accordionContent: {
      display: "flex",
      flexDirection: "column",
    },
    subMenuTitle: {
      "& span": {
        fontWeight: "bold",
      },
    },
    subMenuDescription: {
      padding: "0px 16px",
      marginBottom: "30px",
    },
    primaryMenu: {
      "& span": {
        fontWeight: "bold",
      },
    },
    primaryMenuIcon: {
      minWidth: "30px",
      "& svg": {
        fill: "#000",
      },
    },
    subMenuBackIcon: {
      "& svg": {
        fill: "#000",
        fontSize: "1.2rem",
      },
    },
    mainAccordion: {
      "& svg": {
        fill: "#000",
      },
      "& a": {
        "& svg": {
          fill: "#CC4A01",
        },
      },
    },
    accordionTitle: {
      "& .MuiAccordionSummary-content": {
        margin: "18px 0px",
      },
    },
    linkMobile: {
      "& a": {
        display: "flex",
        alignItems: "center",
        width: "100%",
        color: "rgba(0, 0, 0, 0.87)",
      },
    },
  })
);
