import React from "react";
import { useState } from "react";
import Container from "@material-ui/core/Container";
import Typography from "../../Typography/Typography";
import MenuDetailList from "../MenuDetails/MenuDetailList";
import Arrows from "../../../assets/images/menu/arrow.svg";
import { useStyles } from "./MenuDetails.styles";
import { Link } from "gatsby";

const MenuDetails = () => {
  const classes = useStyles();

  return (
    <div className={classes.menuDetailsWrapper}>
      <Container maxWidth="lg" className={classes.menuTopContent}>
        <div className={classes.typographyh2}>
          <Typography customVariant="h2">
            <a href="/services-overview/">Our Transport & Logistics Services</a>
          </Typography>
        </div>
        <div className={classes.typography}>
          <Typography customVariant="h5Regular">
            You can rely on MOTUS | ONE to deliver world-class event-transport
            management and consultancy. We offer a comprehensive suite of
            services, all tailored to your exact needs.
          </Typography>
        </div>
        <div className={classes.linkButtonWrapperTop}>
          <img src={Arrows} />
          <a href="/services-overview/">Read More</a>
        </div>
      </Container>
      <MenuDetailList />
    </div>
  );
};
export default MenuDetails;
