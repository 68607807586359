import React, { useRef, useEffect } from "react";
import { useState } from "react";
import { useStyles } from "./MainMenu.styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "../../components/Typography/Typography";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Container from "@material-ui/core/Container";
import Button from "../../components/Button/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import MobileMenu from "./MobileMenu/MobileMenu";
import MenuDetails from "./MenuDetails/MenuDetails";
import { mobileViewBreakpoint } from "../helper";
import Link from "gatsby-link";
import Logo from "../../assets/images/MOTUS_COLOR_LOGO.png";

function isActive({ isCurrent }) {
  return isCurrent ? { className: "active" } : null;
}

export default function ButtonAppBar({ window }) {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined
  });
  const classes = useStyles({ trigger });
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const theme = useTheme();
  const [state, setState] = useState({ showSidebar: false });
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  function useOutsideAlerter(ref) {
    useEffect(
      () => {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setIsMenuOpen(false);
          }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      },
      [ref],
      isMenuOpen
    );
  }
  return (
    <>
      {isMobileView && <MobileMenu />}

      <div className={classes.root} ref={wrapperRef}>
        <div className={classes.container}>
          <AppBar
            position="fixed"
            color="inherit"
            position="static"
            className={classes.appBar}
          >
            <Container maxWidth={false} className={classes.menuContainer}>
              <Toolbar>
                <span className={classes.title}>
                  <a href="https://motusone.com/">
                    <img
                      src={Logo}
                      className={classes.logoImage}
                      alt="motus one the event transportation company logo"
                      width="160"
                      height="40"
                    />
                  </a>
                </span>
                <a
                  onClick={e => {
                    setIsMenuOpen(!isMenuOpen);
                    e.stopPropagation();
                  }}
                  className={classes.linkWrapper}
                >
                  <Typography
                    customVariant={isMenuOpen ? "h5Bold" : "h5Regular"}
                    component="div"
                    style={{ cursor: "pointer" }}
                  >
                    What we do
                  </Typography>
                </a>
                <a
                  href="/about-us/"
                  className={classes.linkWrapper}
                  activeStyle={{
                    fontWeight: "bold"
                  }}
                >
                  <Typography
                    customVariant="h5Regular"
                    style={{ fontWeight: "unset" }}
                    component="div"
                  >
                    About us
                  </Typography>
                </a>
                <a
                  href="/case-studies/"
                  className={classes.linkWrapper}
                  activeStyle={{
                    fontWeight: "bold"
                  }}
                >
                  <Typography
                    customVariant="h5Regular"
                    style={{ fontWeight: "unset" }}
                    component="div"
                  >
                    Case Studies
                  </Typography>
                </a>
                <a
                  href="/contact-us/"
                  className={classes.linkWrapper}
                  activeStyle={{
                    fontWeight: "bold"
                  }}
                >
                  <Typography
                    customVariant="h5Regular"
                    style={{ fontWeight: "unset" }}
                    component="div"
                  >
                    Contact Us
                  </Typography>
                </a>
                <a
                  href="/blog/"
                  className={classes.linkWrapper}
                  activeStyle={{
                    fontWeight: "bold"
                  }}
                >
                  <Typography
                    customVariant="h5Regular"
                    style={{ fontWeight: "unset" }}
                    component="div"
                  >
                    Blog
                  </Typography>
                </a>
                <a
                  href="https://app.motusone.com/login"
                  target="_blank"
                  rel="noopener"
                >
                  <Button className={classes.loginButton}>Login</Button>
                </a>
              </Toolbar>
            </Container>
          </AppBar>
          {isMenuOpen && <MenuDetails />}
        </div>
      </div>
    </>
  );
}
