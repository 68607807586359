export const labelBaseStyles = {
  fontStyle: "normal",
  display: "inline-flex",
  alignItems: "center",
  textAlign: "center",
  fontSize: 14,
  textTransform: "none"
};

export const labelSimpleStyles = {
  fontWeight: 700,
  lineHeight: "16px",
  letterSpacing: "0.3px"
};

export const labelContainedStyles = {
  fontWeight: 400,
  lineHeight: "18.8px",
  letterSpacing: "0.3px"
};

export const containedFocusVisible = {
  background: "rgba(64, 1, 197, 0.8)",
  borderColor: "rgba(64, 1, 197, 0.8)"
};

export const disabledBaseStyles = {
  "& $label": {
    color: "rgba(0, 0, 0, 0.2)"
  },
  "& .MuiButton-startIcon svg path": {
    fill: "rgba(0, 0, 0, 0.2)"
  }
};

export const disabledSimplePrimaryStyles = {
  "& $label": {
    color: "rgba(0, 0, 0, 0.3)"
  },
  "& .MuiButton-startIcon svg path": {
    fill: "rgba(0, 0, 0, 0.3)"
  }
};

export const disabledSimpleSecondaryStyles = {
  "& $label": {
    color: "rgba(255, 255, 255, 0.3)"
  },
  "& .MuiButton-startIcon svg path": {
    fill: "rgba(255, 255, 255, 0.3)"
  }
};

export const baseIconRootStyles = {
  height: 16,
  width: "auto"
};

export const basePseudoStyles = {
  transition: "opacity 150ms ease-in-out",
  content: `""`,
  padding: 5,
  borderRadius: "50%",
  position: "absolute",
  left: "50%",
  top: "50%",
  background: "#4001C5",
  transform: "translate(-50%, -50%)",
  height: 24,
  width: 24,
  zIndex: "-1",
  opacity: 0
};
